/* Set up each element with the class '_jsToggle' - the trigger element -
 * so that clicks will toggle a related target element on/off, e.g. to
 * show and hide a menu.
 *
 * The target will be hidden by default, except if it has a 'data-default'
 * attribute with value 'show'.
 *
 * The trigger must have a 'data-target' attribute containing the ID of
 * the target.
 *
 * Additionally, the trigger should have a 'data-hidetext' attribute with
 * text with which the trigger's contents should be replaced when the target
 * is showing. The trigger's initial text contents will be used when the target
 * is hidden. If no 'data-hidetext' attribute is present, the trigger's initial
 * text content will be used also when the target is showing.
 *
 * If the trigger contains an image, 'data-hideimage' should be used to
 * indicate which image should be used when the target is showing. In this
 * case, 'data-hidetext' will be used as the alt text of the image.
 *
 * If the trigger's parent has the hidden attribute, that attribute will be
 * removed.
 *
 * The trigger will receive the class "expanded" when the target element is
 * showing.
 */
(function() {
    function setState($trigger, $target, shouldBeShowing, hideText, showText,
            $image, hideUrl, showUrl) {
        $target[shouldBeShowing ? 'show' : 'hide']();
        $target.attr('aria-hidden', (shouldBeShowing ? 'false' : 'true'));
        $trigger.attr('aria-expanded', (shouldBeShowing ? 'true' : 'false'));
        $trigger[shouldBeShowing ? 'addClass' : 'removeClass']('expanded');
        if ($image.length > 0) {
            $image.attr('src', (shouldBeShowing ? hideUrl : showUrl));
            $image.attr('alt', (shouldBeShowing ? hideText : showText));
        } else {
            $trigger.text(shouldBeShowing ? hideText : showText);
        }
    }

    function setupOnOffToggle($trigger, isDefaultVisible) {
        var targetId = $trigger.data('target');
        var $target = $(document.getElementById(targetId));
        var targetIsShowing = isDefaultVisible;
        var showText = $trigger.text();
        var hideText = $trigger.data('hidetext');
        var showUrl = null;
        var hideUrl = $trigger.data('hideurl');
        var $image = $('img', $trigger);
        if ($image.length > 0) {
            showText = $image.attr('alt');
            showUrl = $image.attr('src');
        }
        if (!hideText) {
            hideText = showText;
        }
        if (!hideUrl) {
            hideUrl = showUrl;
        }
        $trigger.on('click', function() {
            targetIsShowing = !targetIsShowing;
            setState($trigger, $target, targetIsShowing, hideText,
                    showText, $image, hideUrl, showUrl);
        });
        $trigger.attr('aria-controls', targetId);
        $trigger.parent().removeAttr('hidden');
        setState($trigger, $target, targetIsShowing, hideText, showText,
                $image, hideUrl, showUrl);
    }

    function setupTogglesInContainer($container) {
        $('._jsToggle', $container).each(function() {
            var $toggle = $(this);
            var defaultShow = $toggle.data('default') === 'show';
            setupOnOffToggle($toggle, defaultShow);
        });
    }

    setupTogglesInContainer(null);


    window.Toggle = {
        setupTogglesInContainer: setupTogglesInContainer
    }
})();
