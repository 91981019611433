/* Set up each element with the class '_jsSearchTrigger' so that clicking it
 * will cause it to be replaced with a search form.
 *
 * The _jsSearchTrigger element must have a data-searchform attribute whose
 * value is the ID of a search form already in the document. The form that
 * is inserted on click is a copy of this form, except with the ID attribute
 * removed (to avoid duplicates).
 *
 * The trigger will receive role="button" (so that a link can be used, for
 * fallback in non-JS cases). Furthermore, when the form is inserted, its first
 * input element will be focused (among other things, this avoids focus being
 * lost/reset for keyboard users).
 */
(function() {
    $('._jsSearchTrigger').each(function() {
        var $trigger = $(this);
        var $form = $('#' + $trigger.data('searchform')).clone();
        $form.removeAttr('id');

        $trigger.attr('role', 'button');

        $trigger.on('click', function(evt) {
            $trigger.replaceWith($form);
            $form.find('input').focus();
            evt.preventDefault();
        });
    });
})();
