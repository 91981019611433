/* Set up each element with the class '_jsCheckboxFilter' (referred to here as
 * the checkbox container) so that the checkboxes within it can be used to show
 * and hide items in an associated list.
 *
 * The checkbox container must have a data-target attribute containing the ID
 * of the list. Each checkbox must have a data-filter attribute whose value is
 * a class name; when that checkbox is checked, all items that don't have that
 * class name will be hidden from the list.
 *
 * If the checkbox container has the hidden attribute, that attribute will be
 * removed.
 *
 * The script uses the URI fragment (i.e. what follows the "#" character in the
 * URI) to keep track of the filter settings, allowing for history navigation,
 * bookmarking etc. on browsers where session history management is supported.
 * Pages using this script will therefore be unable to use the fragment for
 * scrolling to identified elements.
 */
(function() {
    $('._jsCheckboxFilter').each(function() {
        var $checkboxContainer = $(this);
        var listId = $checkboxContainer.data('target');
        var $list = $('#' + listId);
        var currentFilter = '*';

        function addFilterCondition(condition) {
            currentFilter += '.' + condition;
        }

        function removeFilterCondition(condition) {
            var re = new RegExp('\\.' + condition, 'g');
            currentFilter = currentFilter.replace(re, '');
        }

        function applyFilter() {
            $list.children().hide();
            $(currentFilter, $list).show();
        }

        function processCheckbox($checkbox) {
            var condition = $checkbox.data('filter');
            if ($checkbox.prop('checked')) {
                addFilterCondition(condition);
            } else {
                removeFilterCondition(condition);
            }
            applyFilter();
        }

        function updateUrl() {
            if (history.pushState) {
                history.pushState(currentFilter, document.title,
                        '#!filter=' + currentFilter);
            }
        }

        function updateFilterFromUrl() {
            currentFilter = '*';
            var match = location.hash.match(/!filter=(.+)/);
            if (match) {
                currentFilter = match[1];
            }
        }

        function updateCheckboxesFromFilter() {
            var $checkboxes = $('input[type=checkbox]', $checkboxContainer);
            $checkboxes.each(function() {
                var $checkbox = $(this);
                if (currentFilter.indexOf($checkbox.data('filter')) > -1) {
                    $checkbox.prop('checked', true);
                } else {
                    $checkbox.prop('checked', false);
                }
            });
        }

        function updateStateFromUrl() {
            updateFilterFromUrl();
            updateCheckboxesFromFilter();
            applyFilter();
        }

        $checkboxContainer.on('click', 'input[type=checkbox]', function() {
            processCheckbox($(this));
            updateUrl();
        });
        $(window).on('popstate', updateStateFromUrl);

        updateStateFromUrl();
        $('input[type=checkbox]', $checkboxContainer).attr('aria-controls', listId);
        $list.attr('aria-live', 'polite');
        $checkboxContainer.removeAttr('hidden');
    });
})();
