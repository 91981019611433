/* Sets up automatic syncing of area and length fields within a form, so that
 * the area is updated if the length is changed and vice versa.
 *
 * The output is rounded to two decimals.
 *
 * Used by setting the class "_jsWallDimensionSync" on the form, and making
 * sure it contains input fields for the following three values:
 * - Wall length: its name attribute must be "wallLength"
 * - Wall area: its name attribute must be "wallArea"
 * - Wall height: its name attribute must be "wallHeight"
 */
(function() {
    function roundToTwoDecimals(x) {
        return Math.round(100 * x) / 100;
    }

    $('._jsWallDimensionSync').each(function() {
        var form = this;
        var $lengthInput = $(this.wallLength);
        var $areaInput = $(this.wallArea);
        var $heightInput = $(this.wallHeight);
        var $lastInputChanged = $lengthInput;

        function updateArea() {
            var length = parseFloat($lengthInput.val());
            var height = parseFloat($heightInput.val());
            var area = roundToTwoDecimals(length * height);
            if (isNaN(area)) {
                area = 0;
            }
            $areaInput.val(area);
        }

        function updateLength() {
            var area = parseFloat($areaInput.val());
            var height = parseFloat($heightInput.val());
            var length = roundToTwoDecimals(area / height);
            if (isNaN(length)) {
                length = 0;
            }
            $lengthInput.val(length);
        }

        $lengthInput.on('change', function() {
            $lastInputChanged = $lengthInput;
            updateArea();
        });

        $areaInput.on('change', function() {
            $lastInputChanged = $areaInput;
            updateLength();
        });

        $heightInput.on('change', function() {
            if ($lastInputChanged === $lengthInput) {
                updateArea();
            } else {
                updateLength();
            }
        });
    });
})();
