(function() {
    function setupRangeLabel($range, $valueContainer) {
        /* IE doesn't support input event here */
        $range.on('input change', function(evt) {
            $valueContainer.text($range.val());
        });
        var numericValue = parseFloat($range.val());
        $valueContainer.text(numericValue.toLocaleString());
    }

    function setupRangeExplanations($range, $explanationContainer) {
        function updateExplanation() {
            var currentValue = parseInt($range.val(), 10);
            var highestMatchingValue = 0;
            for (var value in explanations) {
                var numericValue = parseInt(value, 10);
                if (numericValue > highestMatchingValue &&
                    numericValue <= currentValue) {
                    highestMatchingValue = numericValue;
                }
            }
            var explanation = explanations[String(highestMatchingValue)];
            $explanationContainer.text(explanation);
        }

        var explanations = $range.data('explanations');
        if (explanations) {
            updateExplanation();
            /* IE doesn't support input event here */
            $range.on('input change', updateExplanation);
        }
    }

    $('._jsRange').each(function() {
        var $wrapper = $(this);
        var $range = $wrapper.find('input');
        var $rangeValue = $wrapper.find('._jsRangeValue');
        var $rangeValueExplanation =
                $wrapper.find('._jsRangeValueExplanation');
        setupRangeLabel($range, $rangeValue);
        setupRangeExplanations($range, $rangeValueExplanation);
    });
})();
