(function() {
    /* Wraps each table matching the given selector in a new div with the given
     * class name.
     */
    function makeTableWrapper(tableSelector, classNameOfNewDiv) {
        $(tableSelector).wrap('<div class="' + classNameOfNewDiv + '"></div>');
    }

    makeTableWrapper('.mBodyText table', 'tableWrapper');
})();
