/* Creates an alternate rendering of a table where given "input" columns are
 * turned into select dropdowns that function as filters for the rows. The
 * table itself will be hidden initially, but is shown when the user chooses to
 * apply the filter. Data rows not matching the filter are still hidden.
 *
 * The affected table must have and ID attribute (used for ARIA purposes) and
 * must have the class name "_js-tableselect". Furthermore, it must have a
 * 'data-apply-button' attribute giving the ID of the button which will show
 * the table, and a 'data-reset' attribute giving the ID of the button which
 * will be used as a reset button to return the filter to its initial "blank"
 * state.
 *
 * All 'td' and 'th' elements within the table are assumed to contain only
 * plain text, and any 'td' elements outside 'tbody' are ignored. To turn a
 * column into an input column, put a 'th' element in the table's 'thead' with
 * a 'data-input' attribute set to the ID of the select dropdown that will be
 * used to hold the column's (unique) elements.
 *
 * The table will receive a 'hidden' attribute after setup. If the table has a
 * 'data-form-wrapper' attribute, its contents are taken to be the ID of an
 * element whose 'hidden' attribute is to be removed after setup. (This allows
 * for the "raw" table to be shown in fallback cases where the script is not
 * run.)
 *
 * Rows not matching the filter will also be hidden via the 'hidden' attribute.
 */
$('._js-tableselect').each(function() {
    var $table = $(this);
    var $tableDataRows = $('tbody tr', $table);
    var selectMapping = {};
    var data = [];
    var dataFilter = [];
    var currentFilterLength = 0;
    var maxFilterLength = 0;

    function initMappings() {
        $('thead th', $table).each(function(columnIndex, header) {
            var selectId = $(header).data('input');
            if (typeof selectId !== 'undefined') {
                selectMapping[columnIndex] = {
                    $select: $('#' + selectId),
                    filteredOptions: [],
                    filter: null
                };
                maxFilterLength++;
            }
        });
    }

    function hideInputColumns() {
        var columnIndex;
        var nthPseudo;
        for (columnIndex in selectMapping) {
            nthPseudo = ':nth-child(' + (+columnIndex + 1) + ')';
            $('thead th' + nthPseudo, $table).attr('hidden', 'hidden');
            $('td' + nthPseudo, $tableDataRows).each(function() {
                $(this).attr('hidden', 'hidden');
            });
        }
    }

    function initData() {
        $tableDataRows.each(function() {
            var $row = $(this);
            var rowData = [];
            $('td', $row).each(function() {
                rowData.push($(this).text());
            });
            data.push(rowData);
            dataFilter.push(true);
        });
    }

    function initSelects() {
        initSelectOptions();
        initSelectListeners();
        initSelectAria();
    }

    function initSelectOptions() {
        var row;
        var col;
        var value;
        var i;
        var optionsHtml;
        for (row = 0; row < data.length; row++) {
            for (col = 0; col < data[row].length; col++) {
                value = data[row][col];
                if (col in selectMapping &&
                        selectMapping[col].filteredOptions.indexOf(value) < 0) {
                    selectMapping[col].filteredOptions.push(value);
                }
            }
        }
        for (col in selectMapping) {
            selectMapping[col].filteredOptions.sort(function(x, y) {
                if (+x > 0 && +y > 0) {
                    return +x > +y;
                }
                return x > y;
            });
            $('option + option', selectMapping[col].$select).remove();
            optionsHtml = '';
            for (i = 0; i < selectMapping[col].filteredOptions.length; i++) {
                optionsHtml += '<option>' +
                        selectMapping[col].filteredOptions[i] + '</option>';
            }
            selectMapping[col].$select.append(optionsHtml);
        }
    }

    function initSelectListeners() {
        var col;
        for (col in selectMapping) {
            selectMapping[col].$select.on('change', (function(col) {
                return function() {
                    var $select = $(this);
                    if ($select.prop('selectedIndex') > 0) {
                        $select.prop('disabled', true);
                        selectMapping[col].filter = $select.val();
                        currentFilterLength++;
                        updateFilteredOptionsAndDataFilterFromFilter();
                        updateSelectsWithFilteredOptions();
                    }
                };
            })(col));
        }
    }

    function initSelectAria() {
        var col;
        var tableId = $table.attr('id');
        for (col in selectMapping) {
            selectMapping[col].$select.attr('aria-controls', tableId);
        }
    }

    function initResetButton() {
        var $reset = $('#' + $table.data('reset'));
        $reset.on('click', function() {
            var col;
            var $select;
            for (col in selectMapping) {
                selectMapping[col].filter = null;
                $select = selectMapping[col].$select;
                $select.prop('disabled', false);
                $select.prop('selectedIndex', 0);
            }
            currentFilterLength = 0;
            updateFilteredOptionsAndDataFilterFromFilter();
            updateSelectsWithFilteredOptions();
            updateResultOutput(false);
        });
    }

    function updateFilteredOptionsAndDataFilterFromFilter() {
        var row;
        var col;
        var rowDoesMatch;
        var filteredOptions;
        for (col in selectMapping) {
            if (selectMapping[col].filter === null) {
                selectMapping[col].filteredOptions = [];
            }
        }
        for (row = 0; row < data.length; row++) {
            rowDoesMatch = true;
            for (col in selectMapping) {
                if (selectMapping[col].filter !== null) {
                    rowDoesMatch = rowDoesMatch &&
                            (selectMapping[col].filter == data[row][col]);
                }
            }
            if (rowDoesMatch) {
                for (col in selectMapping) {
                    filteredOptions = selectMapping[col].filteredOptions;
                    if (filteredOptions.indexOf(data[row][col]) < 0) {
                        filteredOptions.push(data[row][col]);
                    }
                }
            }
            dataFilter[row] = rowDoesMatch;
        }
    }

    function updateSelectsWithFilteredOptions() {
        var col;
        var i;
        var $select;
        for (col in selectMapping) {
            $('option + option', selectMapping[col].$select).each(function() {
                var $option = $(this);
                var filteredOptions = selectMapping[col].filteredOptions;
                if (filteredOptions.indexOf($option.text()) < 0) {
                    $option.attr('hidden', 'hidden');
                } else {
                    $option.removeAttr('hidden');
                }
            });
        }
    }

    function updateResultOutput(forceShowTable) {
        if (forceShowTable || currentFilterLength > 0) {
            $table.removeAttr('hidden');
        } else {
            $table.attr('hidden', 'hidden');
        }
        $tableDataRows.each(function(index, row) {
            var $row = $(row);
            if (dataFilter[index]) {
                $row.removeAttr('hidden');
            } else {
                $row.attr('hidden', 'hidden');
            }
        });
    }

    function initApplyButton() {
        var $apply = $('#' + $table.data('apply-button'));
        $apply.on('click', function() {
            updateResultOutput(true);
        });
    }

    initMappings();
    hideInputColumns();
    initData();
    initSelects();
    initResetButton();
    initApplyButton();

    $table.attr('hidden', 'hidden');
    if (typeof $table.data('form-wrapper') !== 'undefined') {
        $('#' + $table.data('form-wrapper')).removeAttr('hidden');
    }
});
