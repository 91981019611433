/**
 * Scrolls the specified element into view on page load.
 *
 * The first element in source order which has a class '_jsScrollIntoView'
 * will be scrolled into view. Any subsequent instances of such a class are
 * ignored.
 */
(function() {
    var target = document.querySelector('._jsScrollIntoView');
    if (target && target.scrollIntoView) {
        window.addEventListener('load', function() {
            target.scrollIntoView();
        });
    }
})();
