(function() {
    $('._jsAjaxSubmit').each(function() {
        var $submit = $(this);
        var $form = $($submit.prop('form'));
        var $resultContainer = $('#' + $submit.data('target'));
        $form.on('submit', function(e) {
            $.ajax({
                type: 'GET',
                url: $form.prop('action'),
                data: $form.serialize(),
                success: function(data) {
                    $resultContainer.html(data);
                    Toggle.setupTogglesInContainer($resultContainer);
                }
            });
            e.preventDefault();
        });
    });
})();
