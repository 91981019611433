/* Set up each element with class 'js-overlay' to add support for triggering
 * an "overlay" (showing text and a partially-transparent "filter" background)
 * on mouseover or focus.
 *
 * The actual revealing/hiding of the overlay must be done in CSS. To this end,
 * this script will add a 'data-overlay' attribute on the element, setting it
 * to 'off' when the overlay should be off/hidden and to 'on' when it should be
 * shown.
 *
 * Known issue: The checks for mouseover/focus consider both the element and
 * its descendants, but only consider mouse and focus independently (e.g.
 * mouseout will set the overlay state to 'off' even if focus still is at or
 * within the element).
 */
(function() {
    var overlayBlocks = document.querySelectorAll('.js-overlay');

    function toggleOverlay(element, shouldBeOn) {
        element.setAttribute('data-overlay', shouldBeOn ? 'on' : 'off');
    }

    [].forEach.call(overlayBlocks, function(element) {
        function enableOverlay() {
            toggleOverlay(element, true);
        }

        function disableOverlay() {
            toggleOverlay(element, false);
        }

        disableOverlay(); /* Needed to initialize the data attribute for CSS */
        element.addEventListener('mouseover', enableOverlay);
        element.addEventListener('mouseout', disableOverlay);
        /* Using focusin/focusout (not focus/blur) to get bubbling events */
        element.addEventListener('focusin', enableOverlay);
        element.addEventListener('focusout', function() {
            setTimeout(function() {
                var newFocusedElement = document.activeElement;
                if (!element.contains(newFocusedElement)) {
                    disableOverlay();
                }
            }, 100);
        });
    });
})();
