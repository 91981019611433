/* Sets up a checkbox to (when checked) mark another element as disabled.
 *
 * The disabling is performed both via setting a class (which CSS can use to
 * style the element as disabled; CSS not included) and by setting disabled on
 * an actual select/input (the element to be disabled is assumed to be exactly
 * one such select/input descendant).
 *
 * Usage: set class _jsDisablingCheckbox on the checkbox that is to toggle
 * between disabled and not, and give it a data-disabling attribute with the ID
 * of the (wrapper) element to be disabled.
 */
(function() {
    function setupCheckbox() {
        var $checkbox = $(this);
        var $target = $('#' + $checkbox.data('disabling'));
        var $control = $('input, select', $target);

        function updateState() {
            var shouldDisable = $checkbox.prop('checked');
            $target[shouldDisable ? 'addClass' : 'removeClass']('disabled');
            $control.prop('disabled', shouldDisable);
        }

        $checkbox.on('change', updateState);
        updateState();
    }

    $('._jsDisablingCheckbox').each(setupCheckbox)
})();
