(function() {
    function setupCarousel() {
        var $wrapper = $(this);
        var $slides = $('div', $wrapper);
        var slideCount = $slides.length;
        if (slideCount < 2) {
            return;
        }
        var currentIndex = 0;
        var nextIndex = 1;
        var previousIndex = slideCount - 1;
        $slides[previousIndex].className = 'previous';
        $slides[currentIndex].className = 'current';
        $slides[nextIndex].className = 'next';

        $wrapper.on('transitionend', function() {
            $wrapper.removeClass('sliding');
        });

        function updateCarousel() {
            currentIndex = (currentIndex + 1) % slideCount;
            nextIndex = (nextIndex + 1) % slideCount;
            previousIndex = (previousIndex + 1) % slideCount;
            $slides.removeClass('previous next current');
            $wrapper.addClass('sliding');
            $slides[previousIndex].className = 'previous';
            $slides[currentIndex].className = 'current';
            $slides[nextIndex].className = 'next';
        }

        var interval;
        var $button = $wrapper.siblings('.carouselButton');
        var $play = $('.play', $button);
        var $pause = $('.pause', $button);
        var carouselIsPlaying = false;

        function startCarousel() {
            interval = setInterval(updateCarousel, 4000);
            $pause.show();
            $play.hide();
            carouselIsPlaying = true;
        }

        function stopCarousel() {
            clearInterval(interval);
            $pause.hide();
            $play.show();
            carouselIsPlaying = false;
        }

        $button.on('click', function() {
            if (carouselIsPlaying) {
                stopCarousel();
            } else {
                startCarousel();
            }
        });
        $button.show();

        startCarousel();
    }

    $('.mHighlightedContent.carousel .slides').each(setupCarousel);
})();
